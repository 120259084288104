<template>
  <div>
    <div class="home">
      <img class="home_background" src="@/assets/banner-tc.jpg">
    </div>

    <div class="section_container my-5">
      <div class="container">
        <div class="row">
          <div class="col-xl-10 offset-xl-1">

            <h3 class="text-dark">TERMS AND CONDITIONS</h3>
            <ul>
              <li>
                - Business location and opening hours: Indonesia, 12:00 ~ 20:00 (GMT+7). 
              </li>
              <li>
                - All prices listed are in USD. Payment via PayPal only.
              </li>
              <li>
                - Item status and stock are as described. 
              </li>
            </ul>


            <br>
            <h3 class="text-dark">IMPORTANT</h3>
            <ul>
              <li>
                - If the item status is “PREORDER”, it will take approximately 3-5 weeks to process (this excludes shipping time). We appreciate your kind understanding on this. 
              </li>
              <li>
                - All sales are final and NO REFUND REQUESTS WILL BE ENTERTAINED. 
              </li>
            </ul>


            <br>
            <h3 class="text-dark">SHIPPING</h3>
            <ul>
              <li>
                - We ship items on Fridays and Saturdays.
              </li>
              <li>
                - All items will be shipped from Indonesia. 
              </li>
              <li>
                - Shipping options are either EMS (DHL) or Regular Airmail (RR). 
              </li>
              <li>
                - EMS takes about 5-7 working days. It is a faster and more secure method with tracking number and insurance.
              </li>
              <li>
                - Regular Airmail takes about 14-30 working days. It is inclusive of insurance but no tracking number.
              </li>              
              <li>
                - Shipment to Canada (and some other countries) MUST BE DONE VIA EMS. Since 2017, Canada (and some other countries) rejects Regular Airmail, so please check with your country’s postal service before deciding on your shipping option. Please contact us if you are unsure.
              </li>       
              <li>
                - There may be some cases where packages are slightly delayed due to each country’s customs regulations. 
              </li>       
              <li>
                - We will input the tracking number on your PayPal Invoice once we shipped the package. You can check your package’s status from these websites: http://www.track-trace.com/post http://www.ems.post/tracking http://ems.posindonesia.co.id/ 
              </li>       
              <li>
                - Enter all alphabets and numbers of your tracking code (13 characters) without space. 
              </li>       
              <li>
                  1.) Example 1: EE 123456789 ID ---> EE123456789ID (if you are using EMS) 
              </li> 
              <li>
                  2.) Example 2: RR 123456789 ID ---> RR123456789ID (if you are using Regular Airmail)
              </li> 
              <li>
                - Some country need ID/Tax Number for shipping purposes:
              </li> 
               <li>
                 &nbsp;&nbsp;List of country that need to add ID: 
              </li>
               <li>
                &nbsp;&nbsp;- European Union (EORI)
              </li>
               <li>
                &nbsp;&nbsp;- Brazil(CPF)
              </li>
               <li>
                &nbsp;&nbsp;- Peru (RUH)
              </li>
               <li>
                &nbsp;&nbsp;- Some countries in Latin America
              </li>

              
            </ul>

            <br>
            <h3 class="text-dark">PLEASE NOTE</h3>
            <ul>
              <li>
                - If you have an issue with your package (late arrivals, damages, etc), please contact us via e-mail first and do not report or open any case with PayPal.  
              </li>
              <li>
                - If you are not contacting us within 1,5 month after shipment, you assumed you got your items. Longer than that time period, we can’t track your parcel and we are not responsible for the lost.
              </li>
              <li>
                - If you open a PayPal case without prior communication with us, you will be blacklisted and will not be able to purchase anything from the store going forward. 
              </li>
              <li>
                - If you already got your tracking number, it will be faster to enquire about your package’s status and whereabouts from your local postal service. 
              </li>
              <li>
                - If the package is lost or damaged due to local postal service’s handling error, please note that NO REFUNDS will be given at our end. You can get your money back by filing a claim to your local postal service (shipping insurance is included in every package).
              </li>
              <li>
                - If the package is returned to us because of failed deliveries due to wrong address, uncontactable recipient, etc, we will send it back to you once you have paid the re-shipping cost. 
              </li>
              <li>
                - All tax and customs fees in each country are to be borne fully by the customer. We cannot provide any compensation for said fees. 
              </li>
              <li>
                - Some countries have package inspection regulations upon package arrivals. We are not able to provide compensation and/or exchanges for any item that is lost, seized, or damaged during customs inspections. 
              </li>
              <li>
                - We can mark packages as "gift", but we do not recommend it as a means to lower the item's value. We will not be held responsible if you encounter issues with customs, and we will declare the exact, original value if they investigate. 
              </li>
               <li>
                - If you have any other question, please contact us at project.orangegarden@gmail.com. 
              </li>
              <li>
                - We are very sorry but we CAN'T UNDERVALUE your package's price because there has been a problem with costum and insurance's policies.
              </li>
               <li>
                - If you want to complain about damage/partial lost item, we require you to record the UNBOXING VIDEO, not only photos.
              </li>
              
              <br>
               <li>
               *By placing an order on Brilcrist store, you agree that you have read and understood all the points listed and described above
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'faq',
};
</script>
